<template>
  <div class="container register">
    <AddCode msg="Indtast kode" />
  </div>
</template>

<script>
import AddCode from "@/components/AddCode.vue";

export default {
  name: "Scan",
  components: {
    AddCode,
  },
};
</script>
