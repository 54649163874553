<template>
  <div class="code">
    <div class="form">
      <StreamBarcodeReader
        @decode="onDecode"
        @loaded="onLoaded"
      ></StreamBarcodeReader>

      <div class="code__wrapper">
        <ul class="racks__list">
          <li v-for="item in codes" :key="item">
            {{ item }}
          </li>
        </ul>
      </div>

      <div class="form__error" v-if="error">
        {{ errorMsg }}
      </div>
    </div>

    <div class="buttons single">
      <button class="button" @click="toAddress" :disabled="codes.length <= 0">
        Videre til adresse
      </button>
    </div>
    <router-link @click="next" class="link--register" to="/register"
      >Tilføj stel uden kode</router-link
    >
  </div>
</template>

<script>
import StreamBarcodeReader from "./StreamBarcodeReader.vue";

export default {
  name: "AddCode",
  components: {
    StreamBarcodeReader,
  },
  props: {
    msg: String,
  },
  data: function() {
    return {
      validCode: false,
      codes: [],
      code: null,
      codesAdded: false,
      scan: false,
      error: false,
      errorMsg: "",
    };
  },
  methods: {
    validate() {
      this.error = false;
      this.errorMsg = "";
      var numbers = /^[0-9]+$/;
      if (this.code && this.code.match(numbers)) {
        if (this.code.length <= 10 && this.code.length > 0) {
          if (this.codes.includes(this.code)) {
            this.setError("Kode allerede tilføjet");
            this.validCode = false;
          } else {
            this.validCode = true;
          }
        } else {
          this.validCode = false;
        }
      } else {
        this.validCode = false;
      }
    },
    clearCode() {
      this.reset();
    },

    setError(string) {
      this.errorMsg = string;
      this.error = true;
    },
    reset() {
      this.code = null;
      this.validCode = false;
    },
    onDecode(result) {
      //console.log("decoded", result);
      this.code = result;
      this.storeCode();
    },
    storeCode() {
      if (!this.code) {
        this.code = "0000000000";
      }
      this.validate();
      if (this.code != null && this.validCode) {
        this.codes.push(this.code);
        this.reset();
      }
    },
    onLoaded() {
      //console.log("barcode reader loaded");
    },
    toAddress() {
      if (this.codes.length) {
        this.$store.state.codes = this.codes;
        this.$router.push("/location");
      }
    },

    next(e) {
      e.preventDefault();
      this.$store.state.codes = this.codes;
      this.$router.push("/register");
    },

    async getMedia(constraints) {
      navigator.mediaDevices.getUserMedia(constraints).then(
        // eslint-disable-next-line no-unused-vars
        (stream) => {
          this.scan = true;
          stream.getTracks().forEach((track) => track.stop());
        },
        // eslint-disable-next-line no-unused-vars
        (err) => {
          this.scan = false;
        }
      );
    },
  },
  mounted() {
    if (this.$store.state.codes) {
      this.codes = this.$store.state.codes;
      this.codesAdded = this.codes.length > 0 ? true : false;
    }
  },
};
</script>

<style scoped lang="scss">
.form {
  //margin-top: 8rem;
  margin-bottom: 0;
  input {
    margin-bottom: 0;
  }
  &__error {
    margin: 1rem 0 0;
    color: red;
  }
}
.code {
  width: 100%;
  margin-bottom: 8rem;
  &__wrapper {
    position: relative;

    input {
      width: 100%;
    }
  }
  &__delete {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0.5rem 0;
    padding: 1rem;
    line-height: 1.5;
    cursor: pointer;
  }
}
.scanner-dummy {
  height: 222px;

  width: 100%;
}
.link--register {
  text-align: center;
  display: block;
  color: #2C4390;
}
</style>
