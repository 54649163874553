<template>
  <div class="scanner-container">
    <div class="scanner-container__video" :class="{ greyOut: !scanning }">
      <video poster="data:image/gif,AAAA" ref="scanner"></video>
    </div>
    <div class="loader" v-show="isLoading"></div>
    <div class="scanner-selector" v-if="!scanning">
      <button class="button" @click="scan">
        <i class="fas fa-barcode"></i>Scan kode
      </button>
    </div>
  </div>
</template>

<script>
import {
  BrowserMultiFormatReader,
  Exception,
  DecodeHintType,
  BarcodeFormat,
} from "@zxing/library";

export default {
  name: "stream-barcode-reader",

  data() {
    return {
      deviceId: null,
      device: null,
      isLoading: false,
      scanning: false,
      multipleDevices: false,
      // eslint-disable-next-line no-undef
      codeReader: null,
      isMediaStreamAPISupported:
        navigator &&
        navigator.mediaDevices &&
        "enumerateDevices" in navigator.mediaDevices,
    };
  },

  mounted() {
    if (!this.isMediaStreamAPISupported) {
      throw new Exception("Media Stream API is not supported");
      // eslint-disable-next-line no-unreachable
      return;
    }

    // eslint-disable-next-line no-unused-vars
    this.$refs.scanner.oncanplay = (event) => {
      this.$emit("loaded");
    };
  },

  beforeUnmount() {
    if (this.codeReader) {
      this.codeReader.reset();
    }
  },

  methods: {
    scan() {
      const vm = this;

      const hints = new Map();

      hints.set(DecodeHintType.TRY_HARDER, true);
      hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.CODE_128]);
      vm.codeReader = new BrowserMultiFormatReader(hints);

      vm.scanning = true;

      vm.codeReader.decodeFromVideoDevice(
        undefined,
        vm.$refs.scanner,
        // eslint-disable-next-line no-unused-vars
        (result, err) => {
          if (result) {
            this.isLoading = false;
            vm.scanning = false;

            vm.$emit("decode", result.text);
            vm.codeReader.reset();
          } else if (err) {
            //console.log(err);
            //vm.$emit("barcodeerror", err);
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.scanner-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.scanner-container {
  position: relative;
  border: 1px solid #dbdbdb;

  /**/
  margin: 0.5rem 0;
  border-radius: 4px;
  &__video {
    height: 250px;
    //margin-bottom: 1rem;
    overflow: hidden;
    width: 100%;
    border-radius: 4px;
    &.greyOut {
      opacity: 0.2;
    }
    video {
      width: 100%;
    }
  }
}
</style>
